<template>
  <div>
    <p>&nbsp;</p>
    <div style="display: flex; justify-content: center; align-items: center">
      <div class="contact-container" style="min-width: 80%">
        <form @submit.prevent="submitForm" class="contact-form">
          <h2>Contact us</h2>
          <div class="form-group">
            <label for="email">Email Address (required)</label>
            <input type="email" id="email" v-model="email" required />
          </div>
          <div class="form-group">
            <label for="message">Your Message to Us (required)</label>
            <textarea
              id="message"
              v-model="message"
              rows="5"
              required
            ></textarea>
          </div>
          <button type="submit">Send</button>
        </form>
      </div>
      <div
        v-if="notification.show"
        :class="['notification', notification.type]"
      >
        {{ notification.message }}
      </div>
    </div>
    <p>&nbsp;</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AboutPage",
  data() {
    return {
      email: "",
      message: "",
      notification: {
        show: false,
        message: "",
        type: "success",
      },
    };
  },
  methods: {
    async submitForm() {
      //const baseURL = `${window.location.protocol}//${window.location.hostname}:3001`;
      //const sendURL = `${baseURL}/api/send-email`
      //const sendURL = "http://msg.a13z.org:3001/api/send-email"
      //const sendURL3001 = "https://msg.a13z.org:3001/api/send-email";

      const baseURL = "https://msg.a13z.org:3002";
      try {
        await axios.post(`${baseURL}/api/send-email`, {
          email: this.email,
          message: this.message,
        });

        this.showNotification(" Email sent successfully! ", "success");
        this.resetForm();
      } catch (error) {
        console.error("Error sending email:", error);
        this.showNotification(
          " Failed to send email. Please try again later. " + error,
          "error"
        );
      }
    },
    showNotification(message, type) {
      this.notification = { show: true, message, type };
      setTimeout(() => {
        this.notification.show = false;
      }, 3001);
    },
    resetForm() {
      this.email = "";
      this.message = "";
    },
  },
};
</script>

<style src="../a13z.css" scoped></style>
