<template>
  <div class="sponsor-page" style="padding: 5px">
    <div class="iframe-container">
      <iframe
        src="https://www.getreviews.ai/survey/0676cf?iframe=true"
        id="grEmbed"
        style="min-width: 100%; border-radius: 0.5rem !important"
        sandbox="allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReaderReview",
  mounted() {
    // Dynamically add the iframeResizer script
    const script = document.createElement("script");
    script.src = "https://app.getreviews.ai/javascripts/iframeResizer.min.js";
    script.onload = () => {
      // Initialize iFrameResizer once the script is loaded
      if (window.iFrameResize) {
        window.iFrameResize({ log: true }, "#grEmbed");
      }
    };
    document.head.appendChild(script);
  },
};
</script>

<style scoped>
.iframe-container {
  width: 100%;
  height: 700px; /* Adjust as needed */
  position: relative;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<style src="../a13z.css" scoped></style>
