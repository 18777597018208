<template>
  <div class="buy-now-page">
    <div class="container">
      <div class="book-section">
        <div class="book-cover">
          <img 
            src="../assets/A13Z_We_All_Die_Twice_book_cover.png" 
            alt="We All Die Twice Book Cover"
          />
        </div>
      </div>
      
      <div class="purchase-section">
        <form @submit.prevent="handlePayment(selectedNetwork)">
          <h2>Purchase "We All Die Twice" with Cryptocurencies</h2>
          
          <div class="price-section">
            <table width="400">
              <tr>
                <td>Order Price</td>
                <td align="center">Quantity</td>
              </tr>
              <tr>
                <td>
                  <p>
                    Base Book Price: ${{ basePrice.toFixed(2) }}
                    <br>
                    Total Order Price: ${{ totalPrice.toFixed(2) }}
                  </p>
                </td>
                <td align="center" valign="top">
                  <div>
                    <select 
                      id="quantity"
                      v-model="quantity"
                      :disabled="isProcessing"
                      required
                    >
                      <option v-for="n in 20" :key="n" :value="n">{{ n }}</option>
                    </select>
                  </div>
                </td>
              </tr>
            </table>
          </div>

          <div v-if="isProcessing" class="processing-alert">
            Processing your payment... Please confirm the transaction in your wallet.
          </div>
          
          <div class="form-group">
            <label for="name">Reader's Name:</label>
            <input 
              id="name"
              v-model="name" 
              type="text" 
              required 
              placeholder="Enter your full name"
              class="form-group-input"
            />
            <small>(used only to personalize the welcome message)</small>
          </div>
          
          <div class="form-group">
            <label for="email">Destination Email Address:</label>
            <input 
              id="email"
              v-model="email" 
              type="email" 
              required 
              placeholder="Enter your email"
              class="form-group-input"
            />
            <small>(used to send the ebook as attachment)</small>
          </div>
 
          <div class="form-group terms">
            <input 
              id="conditions"
              v-model="agreedToConditions"
              type="checkbox" 
            />
            <label for="conditions">
              I agree to the purchase terms & conditions 
            </label>
          </div>

          <div class="payment-methods">
            <!-- EVM Chain Payment Buttons -->
            <div 
              v-for="network in evmNetworks" 
              :key="network.name"
              class="payment-button-wrapper"
              :class="{ 
                'hidden': selectedNetwork && selectedNetwork !== network,
                'selected': selectedNetwork === network
              }"
            >
              <button
                type="button"
                class="payment-button"
                :disabled="!isFormValid || isProcessing || isCliquedAlready"
                @click="handlePaymentSelection(network)"
              >
                <span class="payment-text">
                  Pay with <b>{{ network.token.symbol }}</b>&nbsp;<img :src="network.token.logo" :alt="network.token.symbol" class="network-logo" />
                  on <b>{{ network.name }}</b>&nbsp;<img :src="network.logo" :alt="network.name" class="network-logo" />
                </span>
                <div class="logo-group">
                  <img src="MetaMask_Fox.svg" alt="MetaMask" class="network-logo" />
                </div>
              </button>
            </div>

            <!-- Solana Payment Button -->
            <div 
              class="payment-button-wrapper"
              :class="{ 
                'hidden': selectedNetwork && selectedNetwork !== solanaNetwork,
                'selected': selectedNetwork === solanaNetwork
              }"
            >
              <button
                type="button"
                class="payment-button solana-button"
                :disabled="!isFormValid || isProcessing || isCliquedAlready"
                @click="handlePaymentSelection(solanaNetwork)"
              >
                <span class="payment-text">
                  Pay with <b>USDC</b>&nbsp;<img src="usd-coin-usdc-logo.svg" alt="USDC" class="network-logo" /><br>
                  on <b>Solana</b>&nbsp;<img src="solana-sol-logo.svg" alt="Solana" class="network-logo"  height="10px" />
                </span>
                <div class="logo-group">
                  <img src="phantom-wallet.png" alt="Phantom" class="network-logo" />
                </div>
              </button>
            </div>
          </div>

          <div v-if="selectedNetwork" class="action-buttons">
            <button
              type="submit"
              class="confirm-button"
              :disabled="!isFormValid || isProcessing"
              :class="{'expand-contract': isProcessing}"
            >
              Confirm Payment
            </button>
            <button
              type="button"
              class="cancel-button"
              @click="cancelSelection"
            >
              Cancel
            </button>
            <br/>
          </div>
        </form>

        <div v-if="errorMessage" class="error-alert">
          {{ errorMessage }}
        </div>
       <br/>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { createTransferCheckedInstruction, getAssociatedTokenAddress } from '@solana/spl-token';

export default {
  name: 'BuyNowPage',
  data() {
    return {
      name: '',
      email: '',
      quantity: 1,
      basePrice: 3.49,
      agreedToConditions: false,
      errorMessage: '',
      isCliquedAlready: false, // track if the button has been clicked
      isProcessing: false, // track if a payment is being processed
      selectedNetwork: null,
      MERCHANT_ETH_ADDRESS: '0xF55edd85Dbe7FB43b20F8dB8a1fB0d4578419E17',
      MERCHANT_SOLANA_ADDRESS: 'AhN4Z6dALzs9aApDzXnkr7s2GHo3JCne6fWm86Hdp5V1',
      evmNetworks: [
        {
          name: 'Arbitrum',
          chainId: '0xa4b1',
          token: {
            symbol: 'USDC',
            address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
            decimals: 6,
            logo: 'eurc-logo.png'
          },
          logo: 'arbitrum-arb-logo.svg'
        },
        {
          name: 'Ethereum',
          chainId: '0x1',
          token: {
            symbol: 'USDT',
            address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
            decimals: 6,
            logo: 'tether-usdt-logo.svg'
          },
          logo: 'ethereum-eth-logo.svg'
        },
        {
          name: 'Base',
          chainId: '0x2105',
          token: {
            symbol: 'EURC',
            address: '0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c',
            decimals: 6,
            logo: 'eurc-logo.png'
          },
          logo: 'base-logo-in-blue.svg'
        }
      ],
      solanaNetwork: {
        name: 'Solana',
        token: {
          symbol: 'USDC',
          address: 'B7S9ki3WCSLjA5QWXmcTcUcMsmhLvNUuTnK4sEtN41bb',
          decimals: 6,
          logo: 'usd-coin-usdc-logo.svg'
        },
        logo: 'solana-sol-logo.svg'
      }
    };
  },

  computed: {
    totalPrice() {
      return this.basePrice * this.quantity;
    },
    
    isFormValid() {
      return this.name.trim() !== '' && 
             this.email.trim() !== '' && 
             this.agreedToConditions &&
             this.quantity > 0;
    }
  },

  methods: {
    handlePaymentSelection(network) {
      // Disable the button by setting the flag
      this.isCliquedAlready = true;
      this.selectedNetwork = network;
      this.errorMessage = '';
      
    },

    cancelSelection() {
      this.selectedNetwork = null;
      this.errorMessage = '';
    },

    async handlePayment(network) {
      if (!network) return;
      
      this.errorMessage = '';
      this.isProcessing = true;

      try {
        if (!this.isFormValid) {
          throw new Error('Please complete all required fields and agree to conditions.');
        }

        if (network === this.solanaNetwork) {
          await this.handleSolanaPayment();
        } else {
          await this.handleEVMPayment(network);
        }
      } catch (error) {
        console.error('Payment error:', error);
        this.errorMessage = error.message || 'Payment failed. Please try again.';
      } finally {
        this.isProcessing = false;
      }
    },

    async handleEVMPayment(network) {
      if (typeof window.ethereum === 'undefined') {
        throw new Error('Please install MetaMask to make payments');
      }

      // Request account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      
      // Check and switch network if needed
      const chainId = await provider.send('eth_chainId', []);
      if (chainId !== network.chainId) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: network.chainId }]
          });
        } catch (error) {
          if (error.code === 4902) {
            throw new Error(`Please add ${network.name} network to MetaMask`);
          }
          throw new Error(`Failed to switch to ${network.name} network`);
        }
      }

      const signer = provider.getSigner();
      const tokenContract = new ethers.Contract(
        network.token.address,
        [
          'function allowance(address owner, address spender) public view returns (uint256)',
          'function approve(address spender, uint256 amount) public returns (bool)',
          'function transfer(address recipient, uint256 amount) public returns (bool)'
        ],
        signer
      );

      const amount = ethers.utils.parseUnits(
        this.totalPrice.toFixed(network.token.decimals),
        network.token.decimals
      );

      // Check and approve allowance if needed
      const userAddress = await signer.getAddress();
      const allowance = await tokenContract.allowance(userAddress, this.MERCHANT_ETH_ADDRESS);
      if (allowance.lt(amount)) {
        const approvalTx = await tokenContract.approve(this.MERCHANT_ETH_ADDRESS, amount);
        await approvalTx.wait();
      }

      // Execute transfer
      const transferTx = await tokenContract.transfer(this.MERCHANT_ETH_ADDRESS, amount);
      const receipt = await transferTx.wait();
      
      const transactionUrl = `${this.getExplorerUrl(network)}${receipt.transactionHash}`;
      await this.processSuccessfulPayment(receipt.transactionHash, network, transactionUrl);
      
      this.resetForm();
      alert(`Payment successful on ${network.name}! Check your email for confirmation.`);
    },
  

    




      async handleSolanaPayment() {
        if (!window.solana || !window.solana.isPhantom) {
          throw new Error('Please install Phantom wallet');
        }

        try {
          await window.solana.connect();
          
          // List of RPC endpoints to try
          const rpcEndpoints = [
            'https://solana-mainnet.rpc.extrnode.com',
            'https://rpc.ankr.com/solana',
            'https://api.mainnet-beta.solana.com'
          ];

          let workingConnection = null;

          // Try each endpoint until we find one that works
          for (const endpoint of rpcEndpoints) {
            try {
              const testConnection = new Connection(endpoint, 'confirmed');
              // Test the connection with a simple request
              await testConnection.getLatestBlockhash();
              workingConnection = testConnection;
              break;
            } catch (e) {
              console.warn(`RPC endpoint ${endpoint} failed:`, e);
              continue;
            }
          }

          if (!workingConnection) {
            throw new Error('Unable to connect to Solana network. Please try again later.');
          }

          const amount = Math.floor(this.totalPrice * (10 ** this.solanaNetwork.token.decimals));
          const fromPubkey = new PublicKey(window.solana.publicKey);
          const toPubkey = new PublicKey(this.MERCHANT_SOLANA_ADDRESS);
          const tokenMint = new PublicKey(this.solanaNetwork.token.address);

          const fromTokenAccount = await getAssociatedTokenAddress(
            tokenMint,
            fromPubkey
          );
          
          const toTokenAccount = await getAssociatedTokenAddress(
            tokenMint,
            toPubkey
          );

          const transferInstruction = createTransferCheckedInstruction(
            fromTokenAccount,
            tokenMint,
            toTokenAccount,
            fromPubkey,
            amount,
            this.solanaNetwork.token.decimals
          );

          const transaction = new Transaction().add(transferInstruction);

          // Get the latest blockhash from the working connection
          const { blockhash, lastValidBlockHeight } = await workingConnection.getLatestBlockhash('confirmed');
          transaction.recentBlockhash = blockhash;
          transaction.feePayer = fromPubkey;

          // Sign and send the transaction
          const signed = await window.solana.signTransaction(transaction);
          const signature = await workingConnection.sendRawTransaction(signed.serialize(), {
            skipPreflight: false,
            preflightCommitment: 'confirmed'
          });
          
          // Wait for confirmation
          const confirmation = await workingConnection.confirmTransaction({
            signature,
            blockhash,
            lastValidBlockHeight
          });

          if (confirmation.value.err === null) {
            const transactionUrl = `https://solscan.io/tx/${signature}`;
            await this.processSuccessfulPayment(
              signature,
              this.solanaNetwork,
              transactionUrl
            );
            
            this.resetForm();
            alert('Payment successful on Solana! Check your email for confirmation.');
          } else {
            throw new Error('Transaction failed to confirm');
          }

      } catch (error) {
        throw new Error(`Solana payment failed: ${error.message}`);
      }
    },

    async processSuccessfulPayment(transactionHash, network, transactionUrl) {
      try {
        await fetch('https://localhost:3005/api/transaction', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            quantity: this.quantity,
            amount: this.totalPrice,
            network: network.name,
            token: network.token.symbol,
            transactionHash,
            transactionUrl
          }),
        });
      } catch (error) {
        console.error('Error processing payment:', error);
        // Don't throw here as payment was successful
      }
    },

    getExplorerUrl(network) {
      const explorers = {
        Ethereum: 'https://etherscan.io/tx/',
        Arbitrum: 'https://arbiscan.io/tx/',
        Base: 'https://basescan.org/tx/'
      };
      return explorers[network.name] || explorers.Ethereum;
    },

    resetForm() {
      this.name = '';
      this.email = '';
      this.quantity = 1;
      this.agreedToConditions = false;
      this.errorMessage = '';
      this.selectedNetwork = null;
    }
  }
};
</script>

<style scoped>

.error-alert {
  margin-top: 1rem;
  padding: 1rem;
  background: #cce5ff; /* Changed to blue background as requested */
  border: 1px solid #b8daff;
  border-radius: 0.5rem;
  color: #004085;
}

.buy-now-page {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 0, 221);
  color: black;
  background-image: url("../assets/background-together.jpg");
   
}

.container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.book-section {
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 10px;
}

.book-cover img {
  max-width: 100%;
  max-height: 600px;
  object-fit: contain;
  border-radius: 10px;
}

.price-section {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.purchase-section {
  flex: 1;
  padding: 0rem;
  background: #fafafa;
  color:darkcyan;
  border-radius: 10px;
}

form {
  max-width: 500px;
  margin: 0 auto;
}

h2 {
  margin-bottom: 2rem;
  color: #333;
  text-align: center;
}

.form-group {
  margin-bottom: 1.5rem;
  margin-left: 0.5rem;
}

.form-group-input {

}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
}

input[type="text"],
input[type="email"],
select {
  width: 90%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
}

.terms {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.payment-methods {
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
  justify-content: center;
  flex-wrap: wrap;
}

.payment-button-wrapper {
  flex: 1;
  min-width: 150px;
  max-width: calc(52% - 0.5rem);
  transition: all 0.3s ease;
}

.payment-button-wrapper.hidden {
  opacity: 0;
  transform: scale(0.8);
  pointer-events: none;
  position: absolute;
}

.payment-button-wrapper.selected .payment-button {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
  
}

.payment-button-wrapper .payment-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.payment-button {
  width: 100%;
  padding: 1rem;
  background: #017cff;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  
}

.payment-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.payment-text {
  display: block;
  margin-bottom: 0.5rem;
}

.logo-group {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
}

.network-logo {
  height: 24px;
  max-width: 64px;
  object-fit: contain;
  vertical-align:middle ;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.confirm-button,
.cancel-button {
  flex: 1;
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-button {
  background: #28a745;
  color: white;
}

.confirm-button:disabled {
  background: #ccc;
}

.cancel-button {
  background: #dc3545;
  color: white;
}

.error-alert {
  margin-top: 1rem;
  padding: 1rem;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.5rem;
  color: #721c24;
}

.processing-alert {
  margin-bottom: 1rem;
  padding: 1rem;
  background: #cce5ff;
  border: 1px solid #b8daff;
  border-radius: 0.5rem;
  color: #004085;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .payment-button-wrapper {
    max-width: 100%;
  }

  .action-buttons {
    flex-direction: column;
  }
}

/* Animation for expanding and contracting the confirm button */
@keyframes expandContract {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.01); /* 1% expand */
  }
  100% {
    transform: scaleX(1);
  }
}

.confirm-button.expand-contract {
  animation: expandContract 1s infinite; /* Apply rhythm of 1Hz */
}
</style>
