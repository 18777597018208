<template>
  <div style="padding: 5%">
    <div style="display: flex; flex-direction: column; align-items: center">
      <div class="arc-container" style="min-width: 80%; padding: 3%">
        <h1>Advanced Review Copy | Reader Application</h1>
        <h2>So what’s this ARC (Advanced Review Copy) thing all about?</h2>

        <p>Do you like to follow A13Z and want to discover the next book?</p>
        <p>
          So this is the perfect opportunity for you to read our books before
          anyone else.
        </p>
        <p>
          In return, you agree to provide an honest review to help others decide
          if they could like the book.
        </p>
        <p>
          Please note that A13Z can only feasibly give out a limited number of
          ARCs for each book, so please understand that signing up does not
          guarantee you will receive a copy of every book.
        </p>
        <p>
          Sometimes A13Z new books will be available on Amazon initially, so the
          first place you can leave your review would be obviously on Amazon.
          You can request an ARC on any of your preferred platform, but please
          let us know whether you would be willing to LEAVE YOUR HONEST REVIEW
          on it and how other readers could access it.
        </p>
      </div>
      <p>&nbsp;</p>

      <div class="contact-container" style="min-width: 80%; padding: 3%">
        <form @submit.prevent="submitForm" class="arc-form">
          <h2>Apply for ARC Reader</h2>

          <div class="form-group">
            <label for="email">Email Address*</label>
            <input type="email" id="email" v-model="email" required />
          </div>

          <div class="form-group">
            <label for="formats">Formats You Read*</label>
            <div class="checkbox-container">
              <ul>
                <li>
                  <label class="checkbox-item"
                    >eBook&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="checkbox"
                      name="formats"
                      value="ebooks"
                      id="choice_ebooks"
                      v-model="formats"
                    />
                  </label>
                </li>
                <li>
                  <label class="checkbox-item"
                    >Paperback
                    <input
                      type="checkbox"
                      name="formats"
                      value="paperbacks"
                      id="choice_paperbacks"
                      v-model="formats"
                    />
                  </label>
                </li>
                <li>
                  <label class="checkbox-item"
                    >Hardcover
                    <input
                      type="checkbox"
                      name="formats"
                      value="hardcovers"
                      id="choice_hardcovers"
                      v-model="formats"
                    />
                  </label>
                </li>
                <li>
                  <label class="checkbox-item"
                    >Audiobook
                    <input
                      type="checkbox"
                      name="formats"
                      value="audiobooks"
                      id="choice_audiobooks"
                      v-model="formats"
                    />
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <div class="form-group">
            <label for="amazon-review">Your Amazon Review Page*</label>
            <input
              type="url"
              id="amazon-review"
              v-model="amazonReviewPage"
              placeholder="https://www.amazon.com/"
              required
            />
            <p class="form-description">
              We would love to see how you review books on Amazon. Please go to
              Amazon, your account, Profile (under ordering and shopping
              preferences), and copy the URL at the top of the page.
            </p>
          </div>

          <div class="form-group">
            <label for="audible-review"
              >Your Audible Review Page (Optional)</label
            >
            <input
              type="url"
              id="audible-review"
              v-model="audibleReviewPage"
              placeholder="https://"
            />
            <p class="form-description">
              If you are also interested in reviewing audiobooks, please provide
              your Audible review page URL. Go to Audible, under your account
              section, go to your "Listener Page", and copy the website URL.
            </p>
          </div>

          <div class="form-group">
            <label>How Many Books Do You Usually Read Per Month?*</label>
            <div class="radio-container">
              <label class="radio-item">
                <input
                  type="radio"
                  name="books-per-month"
                  value="1-2 books"
                  id="choice_1_2_books"
                  v-model="booksPerMonth"
                  required
                />
                1-2 books
              </label>
              <label class="radio-item">
                <input
                  type="radio"
                  name="books-per-month"
                  value="3-5 books"
                  id="choice_3_5_books"
                  v-model="booksPerMonth"
                  required
                />
                3-5 books
              </label>
              <label class="radio-item">
                <input
                  type="radio"
                  name="books-per-month"
                  value="6-10 books"
                  id="choice_6_10_books"
                  v-model="booksPerMonth"
                  required
                />
                6-10 books
              </label>
              <label class="radio-item">
                <input
                  type="radio"
                  name="books-per-month"
                  value="other"
                  id="choice_other_books"
                  v-model="booksPerMonth"
                />
                Other
                <input
                  type="text"
                  v-if="booksPerMonth === 'other'"
                  placeholder="Other"
                  v-model="otherBooksPerMonth"
                />
              </label>
            </div>
          </div>

          <div class="form-group">
            <label for="commit-time"
              >I Can Commit to Reading ARCs Within*</label
            >
            <select id="commit-time" v-model="commitTime" required>
              <option value="3-4 days">3-4 days</option>
              <option value="1 week">1 week</option>
              <option value="2 weeks">2 weeks</option>
            </select>
            <p class="form-description">
              If you can't read or review a book due to a scheduling conflict,
              let me know when you receive the invitation to review.
            </p>
          </div>

          <div class="form-group">
            <label for="review-post-time"
              >I Can Commit to Posting My Review Within*</label
            >
            <select
              id="review-post-time"
              v-model="amazonReviewPostTime"
              required
            >
              <option value="same-day">Same day as publication</option>
              <option value="1-2-days">1-2 days of publication</option>
              <option value="3-4-days">3-4 days of publication</option>
            </select>
            <p class="form-description">
              I will give you as much advanced notice as possible and send you
              the link once the book is ready.
            </p>
          </div>

          <div class="form-group">
            <label for="anti-piracy">Anti-Piracy Oath*</label>
            <textarea id="anti-piracy2" readonly>
I agree that piracy can really hurt. I will respect the copyright by not sharing, copying, or using this file in any unauthorized manner.
            </textarea>
          </div>

          <button type="submit" :disabled="isSubmitting">Agree & Send</button>
        </form>
      </div>

      <div
        v-if="notification.show"
        :class="['notification', notification.type]"
      >
        {{ notification.message }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ArcApplicationForm",
  data() {
    return {
      email: "",
      formats: [],
      amazonReviewPage: "",
      audibleReviewPage: "",
      booksPerMonth: "",
      otherBooksPerMonth: "",
      commitTime: "",
      amazonReviews: false,
      amazonReviewPostTime: "",
      antiPiracyOath:
        "As an independent author trying to make a living telling stories, piracy can really hurt. Please respect my copyright by not sharing, copying, or using this file in any unauthorized manner.",
      notification: {
        show: false,
        message: "",
        type: "success",
      },
      isSubmitting: false,
    };
  },
  methods: {
    async submitForm() {
      this.isSubmitting = true;
      const baseURL = "https://msg.a13z.org:3003";
      try {
        await axios.post(`${baseURL}/api/arc`, {
          email: this.email,
          formats: this.formats,
          amazonReviewPage: this.amazonReviewPage,
          audibleReviewPage: this.audibleReviewPage,
          booksPerMonth: this.booksPerMonth,
          otherBooksPerMonth: this.otherBooksPerMonth,
          commitTime: this.commitTime,
          amazonReviews: this.amazonReviews,
          amazonReviewPostTime: this.amazonReviewPostTime,
        });

        this.showNotification("ARC request sent successfully!", "success");
        this.resetForm();
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 200 range
          this.showNotification(
            error.response.data.message || "Request failed",
            "error"
          );
        } else if (error.request) {
          // Request was made but no response was received
          this.showNotification(
            "Network error, please check your connection",
            "error"
          );
        } else {
          // Something else happened while setting up the request
          this.showNotification("An error occurred: " + error.message, "error");
        }
      } finally {
        this.isSubmitting = false;
      }
    },

    showNotification(message, type) {
      this.notification = { show: true, message, type };
      setTimeout(() => {
        this.notification.show = false;
      }, 3000);
    },
    resetForm() {
      this.email = "";
      this.formats = [];
      this.amazonReviewPage = "";
      this.audibleReviewPage = "";
      this.booksPerMonth = "";
      this.otherBooksPerMonth = "";
      this.commitTime = "";
      this.amazonReviews = false;
      this.amazonReviewPostTime = "";
    },
  },
};
</script>

<style src="../a13z.css" scoped></style>
