<template>
  <div class="nft-page">
    <header>
      <ul>
        <h1>Unlock the World of "We All Die Twice" with Exclusive NFTs</h1>
      </ul>
    </header>
    <div class="text-page-nft">
      <br />
      <div class="nft-cover">
        <img src="../assets/Solana-NFT.webp" alt="A13Z Solana NFT " />
      </div>
      <div class="nft-info">
        <p>
          Get ready to dive deeper into the universe of We All Die Twice by A13Z
          with our exclusive collection of NFTs. Each NFT is a unique digital
          asset that captures the essence of the story, from the striking book
          cover to the iconic characters, places, and artifacts that define the
          narrative.
        </p>
        <h2>What’s in the Collection?</h2>
        <ul>
          <li>
            <b>Book Cover NFT:</b> Own a piece of literary history with the NFT
            of the book cover. This limited edition collectible showcases the
            artistry that sets the tone for the thrilling journey inside.
          </li>
          <li>
            <b>Character NFTs:</b> From the enigmatic Akira Nakamura Z to the
            shadowy figures that populate the story, each major character is
            immortalized in a one-of-a-kind NFT. Collect your favorite
            characters and trade them with other fans.
          </li>
          <li>
            <b>Place NFTs:</b> Key locations within the story, each with their
            own atmosphere and significance, are captured in this series. From
            dark alleyways to hidden vaults, these NFTs bring the setting to
            life.
          </li>
          <li>
            <b>Artifact NFTs:</b> The digital vault key, the mysterious notes,
            and other significant objects from the plot are available as NFTs,
            each representing a crucial element of the story.
          </li>
        </ul>
        '
        <div class="text-banner-nft">
          <h2>Why These NFTs Are Special</h2>
          <ul>
            <li>
              <b>Tradable on OpenSea:</b> All NFTs in this collection are
              tradable on OpenSea, the world’s largest NFT marketplace. Whether
              you're a collector or a fan, you can buy, sell, or trade these
              NFTs with others in the community.
            </li>
            <li>
              <b>Minted on Solana:</b> Our NFTs are minted on the Solana
              blockchain, known for its high speed and low fees. This ensures
              that your transactions are fast, efficient, and environmentally
              friendly.
            </li>
          </ul>
          <h2>Join the Community</h2>
          <p>
            By owning one of these NFTs, you’re not just getting a digital
            asset—you’re becoming part of a vibrant community of fans and
            collectors. Connect with others who share your passion for We All
            Die Twice, and be the first to hear about upcoming releases and
            special events.
          </p>
        </div>
        <h2>Get Yours Now</h2>
        <div class="text-banner-nft">
          <p>
            Don’t miss out on the chance to own a piece of the We All Die Twice
            legacy. Explore the collection on OpenSea, and start building your
            set of NFTs today.
          </p>
        </div>
      </div>
      <p>&nbsp;</p>
    </div>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  // Component logic here
};
</script>

<style src="../a13z.css" scoped></style>
