<template>
  <div class="main">
    <MenuComponent />
    <router-view />
  </div>
</template>

<script>
import MenuComponent from "./components/MenuComponent.vue";
/* import Home from "./components/Home.vue";
import AboutPage from "./components/AboutPage.vue";
import SponsorPage from "./components/SponsorPage.vue";
*/
export default {
  name: "App",
  components: {
    MenuComponent,
  },
};
</script>

<style src="./a13z.css" scoped></style>
