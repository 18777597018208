<template>
  <div class="contact-page">
    <div class="contact-container" style="padding-top: 2%; padding-bottom: 2%">
      <div class="distributors">
        <div class="distributor-item">
          <a
            href="https://www.barnesandnoble.com/w/we-all-die-twice-akira-nakashimaz/1146296409"
            target="_blank"
          >
            <img
              id="distributor-pi"
              alt="Barnes &amp; Noble"
              class="distributor-image"
              src="/publishing-logo-pi.png"
              style="max-width: 160px; max-height: 41px"
          /></a>
        </div>
        <div class="distributor-item">
          <a
            href="https://books.apple.com/us/book/we-all-die-twice/id6698875234"
            target="_blank"
          >
            <img
              id="distributor-ibooks"
              alt="Apple"
              class="distributor-image"
              src="/publishing-logo-ibooks.png"
              style="max-width: 160px; max-height: 41px"
          /></a>
        </div>
        <div class="distributor-item">
          <a
            href="https://www.kobo.com/th/en/ebook/we-all-die-twice"
            target="_blank"
          >
            <img
              id="distributor-kobo"
              alt="Kobo"
              class="distributor-image"
              src="/publishing-logo-kobo.png"
              style="max-width: 160px; max-height: 41px"
          /></a>
        </div>
        <div class="distributor-item">
          <a
            href="https://www.everand.com/book/769238018/We-All-Die-Twice-A13Z-2"
            target="_blank"
          >
            <img
              id="distributor-scribd"
              alt="Everand"
              class="distributor-image"
              src="/publishing-logo-scribd.png"
              style="max-width: 160px; max-height: 41px"
          /></a>
        </div>
        <div class="distributor-item">
          <a
            href="https://www.thalia.de/shop/home/artikeldetails/A1073085537"
            target=""
          >
            <img
              id="distributor-tolino"
              alt="Tolino"
              class="distributor-image"
              src="/publishing-logo-tolino.png"
              style="max-width: 160px; max-height: 41px"
          /></a>
        </div>
        <div class="distributor-item">
          <img
            id="distributor-overdrive"
            alt="OverDrive"
            class="distributor-image"
            src="/publishing-logo-overdrive.png"
            style="max-width: 160px; max-height: 41px"
          />
          <div
            style="
              margin-top: 10px;
              font-size: 0.8em;
              font-weight: bold;
              text-transform: uppercase;
            "
          ></div>
        </div>
        <div class="distributor-item">
          <img
            id="distributor-bt"
            alt="Baker &amp; Taylor"
            class="distributor-image"
            src="/publishing-logo-bt.png"
            style="max-width: 160px; max-height: 41px"
          />
        </div>
        <div class="distributor-item">
          <img
            id="distributor-odilo"
            alt="Odilo"
            class="distributor-image"
            src="/publishing-logo-odilo.png"
            style="max-width: 160px; max-height: 41px"
          />
        </div>
        <div class="distributor-item">
          <a
            href="https://shop.vivlio.com/product/9798895462492_9798895462492_10020/we-all-die-twice"
          >
            <img
              id="distributor-vivlio"
              alt="Vivlio"
              class="distributor-image"
              src="/publishing-logo-vivlio.png"
              style="max-width: 160px; max-height: 41px"
          /></a>
        </div>
        <div class="distributor-item">
          <img
            id="distributor-borrowbox"
            alt="BorrowBox"
            class="distributor-image"
            src="/publishing-logo-borrowbox.png"
            style="max-width: 160px; max-height: 41px"
          />
        </div>
        <div class="distributor-item">
          <a
            href="https://www.smashwords.com/books/view/1618482"
            target="_blank"
          >
            <img
              id="distributor-smashwords"
              alt="Smashwords"
              class="distributor-image"
              src="/publishing-logo-smashwords.png"
              style="max-width: 160px; max-height: 41px"
          /></a>
        </div>
        <div class="distributor-item">
          <a
            href="https://market.thepalaceproject.org/item/6156139"
            target="_blank"
          >
            <img
              id="distributor-dpla"
              alt="Palace Marketplace"
              class="distributor-image"
              src="/publishing-logo-dpla.png"
              style="max-width: 160px; max-height: 41px"
          /></a>
        </div>
        <div class="distributor-item">
          <img
            id="distributor-gardners"
            alt="Gardners"
            class="distributor-image"
            src="/publishing-logo-gardners.png"
            style="max-width: 160px; max-height: 41px"
          />
        </div>
        <div class="distributor-item">
          <a href="https://fable.co/book/x-9798895462492" target="_blank">
            <img
              id="distributor-fable"
              alt="Fable"
              class="distributor-image"
              src="/publishing-logo-fable.png"
              style="max-width: 160px; max-height: 41px"
          /></a>
        </div>
        <div class="distributor-item">
          <a
            href="https://www.amazon.com/We-All-Die-Twice-mastermind-ebook/dp/B0DGLPY6W3/"
            target="_blank"
          >
            <img
              id="distributor-gardners"
              alt="Amazon"
              class="distributor-image"
              src="/amazon-logo-png.png"
              style="max-width: 160px; max-height: 41px"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Available_At",
  // Component logic here
};
</script>
<style src="../a13z.css" scoped></style>
