<template>
  <div class="about-page">
    <header>
      <ul>
        <h1>
          All about Mr
          <img src="../assets/A13Z-logo.png" height="17%" width="17%" />
        </h1>
      </ul>
      <!-- Copy the header from BookLandingPage.vue -->
    </header>
    <div class="text-page-about">
      <br />

      <div class="about-info">
        <p>Ever wondered who—or what—Akira NAKASHIMAZ really is?</p>
        <div style="text-align: center; padding-top: 10px">
          <img src="../assets/A13Z-logo.png" alt="A13Z" />
        </div>
        <p>
          By the way, A13Z is pronounced "A-teen-Z." It’s inspired by a
          compression method, used by geeks like Akira, where you replace a word
          with its first and last letters and in the middle put the number of
          characters in between — like i18n for "internationalization." Because
          really, who wouldn’t want to sound both cryptic and cool at the same
          time?
        </p>
        <p>
          Who is he? Some say he’s a mysterious, talented hacker trading
          zero-day exploits* from Bangkok. Several have seen him working at a
          security audit firm across various continents, while others claim he’s
          actually a group of rogue AI agents who decided that writing thrillers
          was more fun than crashing markets.
        </p>

        <p>
          The truth? It's just as elusive as the tokens worth millions he chases
          after.
        </p>
        <p>
          What we do know is this: A13Z Editions isn’t your typical publishing
          house. It’s more like a decentralized conspiracy of literary
          masterminds. They operate in the shadows, probably meeting in
          encrypted chat rooms where the password changes every 13 minutes (or
          so we’ve heard). No board meetings, no coffee breaks — just pure,
          unfiltered creativity, flowing like a blockchain on overdrive.
        </p>
        <p>
          One thing’s for sure: when Akira NAKASHIMAZ and A13Z Editions drop a
          new book, it’s not just a release—it’s an event. Whether it's cracking
          the code to the next bestseller or just trying to remember where they
          left the digital vault key, this crew knows how to keep the intrigue
          alive.
        </p>
        <p>&nbsp;</p>
        <hr />
        <p style="font-size: smaller">
          * zero-day exploits: Zero-day exploits are vulnerabilities that are
          unknown to the developer. The term "zero-day" refers to the fact that
          no-one has been discovered yet so it has zero days (“fresh from the
          day”) making it highly valuable in the cybercriminal world.
        </p>
      </div>
      <div class="about-cover">
        <img
          src="../assets/akira.png"
          alt="Akira view from the back"
          class="mobile-hide"
        />
      </div>
      <p>&nbsp;</p>
    </div>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  // Component logic here
};
</script>

<style src="../a13z.css" scoped></style>
