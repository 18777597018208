<template>
  <div class="sponsor-page">
    <br />
    <header>
      <ul>
        <h1>
          Support the Next Chapter of We All Die Twice – Become a Sponsor Today!
        </h1>
      </ul>
      <!-- Copy the header from BookLandingPage.vue -->
    </header>
    <div class="text-page">
      <p>
        <br />
        <b>We All Die Twice</b> by A13Z is set to captivate more and more
        readers. We're expanding with 5,000 additional copies, launching a bold
        advertising campaign, and preparing for the next adrenaline-pumping
        sequel.
      </p>
      <p>But we need partners like you to make it happen!</p>
      <h2>Why Sponsor Us?</h2>
      <div class="widget-container">
        <b>1. Your Brand, Front and Center: </b>
        <p>
          Align your brand with a captivating thriller series that's poised to
          become a cultural sensation.
        </p>
        <ul>
          <li>
            Branded Copies: Imagine your logo on the cover, a perfect
            end-of-year gift for your VIPs.
          </li>
          <li>
            In the Story: Your brand, woven into the plot—immortalized in a book
            fans can’t put down.
          </li>
          <li>
            Full-Page Feature: Showcase your brand in the book with a dedicated
            page.
          </li>
        </ul>

        <b>2. Great Visibility:</b>
        <ul>
          <li>
            On Our Website: Your logo front and center, seen by thousands of
            engaged readers.
          </li>
          <li>
            Reader Community: We’ll promote your brand directly to our
            tech-savvy, thriller-loving fan base.
          </li>
        </ul>

        <b>3. Targeted Engagement: </b>
        <p><ul><li>
          Connect with our tech-savvy, thriller-loving reader community through
          various promotional channels.</li></ul>
        </p>

        <b>4. Corporate Gifting: </b>
        <p><ul><li>
          Provide your VIPs with branded copies of a gripping novel that they
          won't be able to put down.</li></ul>
        </p>

        <b>5. Innovative Storytelling: </b>
        <p><ul><li>
          Integrate your brand into the story, creating a unique and
          unforgettable association.</li></ul>
        </p>

        <b>6. Be Part of the Next Big Thing:</b>
        <ul>
          <li>
            Your sponsorship isn’t just about one book—it’s about fueling a
            series that’s poised to be a cultural sensation.
          </li>
          <li>Ready to Join Us?</li>
        </ul>
      </div>
      <p>
        Choose your sponsorship package and let’s create something unforgettable
        together.
      </p>
      <div class="sponsorship-packages">
        <div class="mobile-hide">
  <div class="package associate">
    <h3>Seed Sponsor</h3>
    <p><strong>$5,000</strong></p>
    <ul>
      <li>Logo on acknowledgments page</li>
      <li>Dedicated blog post</li>
      <li>Quarterly social media shout-outs</li>
      <li>50 branded copies</li>
    </ul>
  </div></div>
  <div class="mobile-hide">
  <div class="package premier">
    <h3>Premier Sponsor</h3>
    <p><strong>$10,000</strong></p>
    <ul>
      <li>Half-page feature in the book</li>
      <li>Dedicated email blast</li>
      <li>Monthly social media shout-outs</li>
      <li>100 branded copies</li>
    </ul>
  </div></div>
  <div class="mobile-hide">
  <div class="package elite">
    <h3>Elite Sponsor</h3>
    <p><strong>$20,000</strong></p>
    <ul>
      <li>Full-page feature in the book</li>
      <li>Dedicated email marketing campaign</li>
      <li>Social media shout-outs</li>
      <li>200 branded copies</li>
    </ul>
  </div></div>
</div>

  <div class="package2 premier">
    <h3 style="text-align: center;">Premier Sponsor</h3>
    <p style="text-align: center;"><strong>$10,000</strong></p>
    <ul>
      <li>Half-page feature in the book</li>
      <li>Dedicated email blast</li>
      <li>Monthly social media shout-outs</li>
      <li>100 branded copies</li>
    </ul>
  </div>
  <br/>
  <div class="package2 elite">
    <h3 style="text-align: center;">Elite Sponsor</h3>
    <p style="text-align: center;"><strong>$20,000</strong></p>
    <ul>
      <li>Full-page feature in the book</li>
      <li>Dedicated email marketing campaign</li>
      <li>Social media shout-outs</li>
      <li>200 branded copies</li>
    </ul>
  </div>
<br/>
      <header>
      <h1 class="slogan">Your brand + our book = a story for the ages. </h1></header>
      <p style="text-align: center;" class="highlight">
        <a href="https://a13z.org//contact#/contact"><b> Get in Touch </b></a> &numsp; to explore this
        exciting opportunity!
      </p>
      <p>Explore this exciting opportunity to partner with "We All Die Twice" by A13Z and create something extraordinary together. Contact us to discuss the perfect sponsorship package for your brand.
</p><p>
By offering these packages and focusing on the benefits of brand association, visibility, and engagement, you'll create attractive sponsorship opportunities without promising specific placement or sales figures.</p>
      <!-- Add content for the sponsor page -->
      <p>&nbsp;</p>
    </div>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "SponsorPage",
  // Component logic here  
};
</script>

<style src="../a13z.css" scoped></style>
