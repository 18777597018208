import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHashHistory } from "vue-router";
import Home from "./components/Home.vue";
import AboutPage from "./components/AboutPage.vue";
import SponsorPage from "./components/SponsorPage.vue";
import NftPage from "./components/NFT.vue";
import Contact from "./components/Contact.vue";
import Contact3Chapters from "./components/Contact3Chapters.vue";
import ReaderReview from "./components/ReaderReview.vue";
import ArcApplicationForm from "./components/ArcApplicationForm.vue";
import Available from "./components/Available_At.vue";
import BuyNowPage from "./components/BuyNowPage.vue";
import PayPage from "./components/PaymentPage.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/about", component: AboutPage },
  { path: "/sponsor", component: SponsorPage },
  { path: "/nft", component: NftPage },
  { path: "/contact", component: Contact },
  { path: "/ReaderReview", component: ReaderReview },
  { path: "/AdvancedReviewCopy", component: ArcApplicationForm },
  { path: "/free-excerpt", component: Contact3Chapters },
  { path: "/available", component: Available },
  { path: '/buy-now', component: BuyNowPage},
  { path: '/pay', component: PayPage},
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
});

createApp(App).use(router).mount("#app");
