<template>
  <div class="mobile-cover">
    <h2 style="color: whitesmoke">
      Latest release by <span class="A13Z">A13Z</span> :
    </h2>
  </div>
  <div class="mobile-cover">
    <h1>We All Die Twice</h1>
  </div>
  <div class="mobile-cover"></div>
  <div class="mobile-hide">
    <img
      src="../assets/A13Z_book_cover.png"
      alt="We All Die Twice Book Cover"
      class="cover-image"
      style="align-items: center"
    />
  </div>
  <div class="mobile-unhide">
    <img
      src="../assets/A13Z_book_cover_small.png"
      alt="We All Die Twice Book Cover Small"
      style="align-items: center"
    />
  </div>
  <div class="mobile-cover"></div>
  <div class="cover-text">
    <div class="widget-container">
      <p>
        Canada’s largest cryptocurrency exchange crashed, vanishing with a
        jaw-dropping hundreds of million.
      </p>
      <p>
        The CEO was found dead under mysterious circumstances in India, leaving
        behind a trail of unanswered questions and a digital vault key lost
        forever.
      </p>
      <p>But can a heist of this magnitude truly be forgotten?</p>
      <p>
        Enter A13Z, better known as Akira, the legendary hacker with a talent
        for unearthing digital secrets.
      </p>
      <p>
        On an unrelenting quest for the truth, Akira is determined to chase it
        to the ends of the Earth... all the way until Bhutan's last peak.
      </p>
      <p>Freely inspired by absolutely true events.</p>
    </div>
    <button @click="openLink" class="learn-more">Learn More ...</button>
  </div>

  <div class="book-landing-page">
    <main>
      <div class="mobile-hide">
        <section id="hero">
          <div class="book-cover">
            <img
              class="mobile-hide"
              src="../assets/A13Z_We_All_Die_Twice_book_cover.png"
              alt="We All Die Twice Book Cover"
            />
          </div>
          <div class="book-info">
            <h2 style="color: whitesmoke">
              Latest release by
              <span class="A13Z">A13Z</span> :
            </h2>
            <h1>We All Die Twice</h1>
            <div class="widget-container">
              <p>
                Canada’s largest cryptocurrency exchange crashed, vanishing with
                a jaw-dropping hundreds of millions.
              </p>
              <p>
                The CEO was found dead under mysterious circumstances in India,
                leaving behind a trail of unanswered questions and a digital
                vault key lost forever.
              </p>
              <p>But can a heist of this magnitude truly be forgotten?</p>
              <p>
                Enter A13Z, better known as Akira, the legendary hacker with a
                talent for unearthing digital secrets.
              </p>
              <p>
                On an unrelenting quest for the truth, Akira is determined to
                chase it to the ends of the Earth... all the way until Bhutan's
                last peak.
              </p>
              <p>Freely inspired by absolutely true events.</p>
            </div>
            <button @click="openLink" class="learn-more">Learn More ...</button>
            <div class="data">
              <ul>
                <li>Category: Crypto Thriller</li>
                <li>Genre: Airport Novel</li>
                <li>Format: Paperback</li>
                <li>ISBN: 2842670752</li>
                <li>Author: Akira NAKASHIMAZ</li>
                <li>Editions: A13Z</li>
                <li>Dimensions: Height: 8", Length: 5", Spine Width: 0.383"</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <p>&nbsp;</p>
      <div class="contact-page">
        <div class="contact-container">
          <div class="image-container">
            <img
              src="../assets/we-all-die-twice-kindle-tablet-cover.png"
              alt="We All Die Twice Cover"
              class="fade-in"
            />
          </div>
          <form
            @submit.prevent="submitForm"
            class="contact-form"
            style="padding-left: 5px"
          >
            <h2>Get Your 3 Free Chapters!</h2>
            <div class="form-group">
              <label for="email">Email Address (required)</label>
              <input type="email" id="email" v-model="email" required />
            </div>
            <div class="form-group">
              <label for="message">Your Message to Us (optional)</label>
              <textarea id="message" v-model="message" rows="5"></textarea>
            </div>
            <button type="submit">Send</button>
          </form>
        </div>
        <div
          v-if="notification.show"
          :class="['notification', notification.type]"
        >
          {{ notification.message }}
        </div>
      </div>
    </main>
    <p>
      <!-- Use the Available_At component -->
      <Available_At />
    </p>
    <footer>
      <section id="author-info">
        <h2 style="color: aliceblue">
          &copy; {{ new Date().getFullYear() }} Akira NAKASHIMAZ
        </h2>
      </section>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import Available_At from "./Available_At.vue";

export default {
  name: "BookLandingPage",
  components: {
    Available_At,
  },
  data() {
    return {
      email: "",
      message: "",
      notification: {
        show: false,
        message: "",
        type: "success",
      },
    };
  },
  methods: {
    openLink() {
      window.open(
        "https://www.amazon.com/We-All-Die-Twice-mastermind-ebook/dp/B0DGLPY6W3/",
        "_blank"
      );
    },
    async submitForm() {
      const sendURL = "https://msg.a13z.org:3001/api/send-email";
      try {
        await axios.post(sendURL, {
          email: this.email,
          message: this.message,
        });
        this.showNotification(
          "Free chapters sent successfully! Check your email.",
          "success"
        );
        this.resetForm();
      } catch (error) {
        console.error("Error sending email:", error);
        this.showNotification(
          `Failed to send free chapters. Please try again. \n ${error} \n ${sendURL}`,
          "error"
        );
      }
    },
    showNotification(message, type) {
      this.notification = { show: true, message, type };
      setTimeout(() => {
        this.notification.show = false;
      }, 3000);
    },
    resetForm() {
      this.email = "";
      this.message = "";
    },
  },
};
</script>

<style scoped>
/* Add your scoped CSS styles here */
</style>

<style src="../a13z.css" scoped></style>
