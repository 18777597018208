<!-- npm install vue qrcode.vue -->
<template>
  <div class="container mx-auto p-4">
    <!-- Shopping Cart -->
    <div class="bg-white rounded-lg shadow p-6 mb-6">
      <h2 class="text-2xl font-bold mb-4">Shopping Cart</h2>
      <div class="overflow-x-auto">
        <table class="w-full">
          <thead>
            <tr class="border-b">
              <th class="text-left p-2">Product</th>
              <th class="text-right p-2">Price (USD)</th>
              <th class="text-right p-2">Quantity</th>
              <th class="text-right p-2">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in cartItems" :key="item.id" class="border-b">
              <td class="p-2">{{ item.name }}</td>
              <td class="text-right p-2">${{ item.price.toFixed(2) }}</td>
              <td class="text-right p-2">{{ item.quantity }}</td>
              <td class="text-right p-2">${{ (item.price * item.quantity).toFixed(2) }}</td>
            </tr>
            <tr class="font-bold">
              <td class="p-2">Total</td>
              <td></td>
              <td class="text-right p-2">{{ totalQuantity }}</td>
              <td class="text-right p-2">${{ cartTotal.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Cryptocurrency Selection -->
    <div class="bg-white rounded-lg shadow p-6 mb-6">
      <h2 class="text-2xl font-bold mb-4">Choose Payment Method</h2>
      
      <!-- Chain Selection -->
      <div class="mb-4">
        <h3 class="text-lg font-semibold mb-2">Select Chain</h3>
        <div class="flex gap-4">
          <button
            v-for="chain in availableChains"
            :key="chain"
            @click="selectChain(chain)"
            :class="[
              'px-4 py-2 rounded-lg border flex items-center gap-2',
              selectedChain === chain ? 'bg-blue-500 text-white' : 'bg-white'
            ]"
          >
            <img 
              :src="getChainLogo(chain)"
              :alt="`${chain} logo`"
              class="w-6 h-6 object-contain"
              @error="handleImageError"
            />
            {{ chain }}
          </button>
        </div>
      </div>

      <!-- Token Selection -->
      <div class="mb-4" v-if="selectedChain">
        <h3 class="text-lg font-semibold mb-2">Select Token</h3>
        <div class="flex gap-4">
          <button
            v-for="token in availableTokens"
            :key="token"
            @click="selectToken(token)"
            :class="[
              'px-4 py-2 rounded-lg border flex items-center gap-2',
              selectedToken === token ? 'bg-blue-500 text-white' : 'bg-white'
            ]"
          >
            <img 
              :src="getTokenLogo(selectedChain, token)"
              :alt="`${token} logo`"
              class="w-6 h-6 object-contain"
              @error="handleImageError"
            />
            {{ token }}
          </button>
        </div>
      </div>

      <!-- Crypto Amount -->
      <div v-if="selectedToken && currentAmount" class="text-xl font-bold">
        Amount to Pay: {{ currentAmount }} {{ selectedToken }}
      </div>
    </div>

    <!-- Warning Message -->
    <div class="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6">
      <p class="text-yellow-800">
        Cryptocurrency payments are non-reversible. Please verify all details before proceeding.
        By continuing, you agree to our terms and conditions. For refund inquiries, please
        contact the merchant directly.
      </p>
    </div>

    <!-- Payment Steps -->
    <div class="mb-6">
      <div class="flex justify-between">
        <div
          v-for="step in 5"
          :key="step"
          class="flex flex-col items-center flex-1"
        >
          <div
            :class="[
              'w-8 h-8 rounded-full flex items-center justify-center mb-2',
              step <= currentStep ? 'bg-blue-500 text-white' : 'bg-gray-200'
            ]"
          >
            {{ step }}
          </div>
          <div class="text-sm text-center">{{ getStepLabel(step) }}</div>
        </div>
      </div>
    </div>

    <!-- Payment Button -->
    <div class="flex justify-center" v-if="selectedToken">
      <button
        @click="handlePayment"
        class="bg-blue-500 text-white px-6 py-3 rounded-lg font-bold"
      >
        Pay Now
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

// Default configuration
const DEFAULT_CONFIG = {
  chains: ['Solana', 'Arbitrum'],
  tokens: {
    Solana: ['SOL', 'USDC', 'USDT'],
    Arbitrum: ['ETH', 'USDC', 'USDT']
  },
  wallets: {
    Solana: 'SOLANA_WALLET_ADDRESS',
    Arbitrum: 'ARBITRUM_WALLET_ADDRESS'
  }
}

// State
const cartItems = ref([
  { id: 1, name: 'Ice Cream 2', price: 2.50, quantity: 1 },
  { id: 2, name: 'Ice Cream', price: 1.99, quantity: 1 }
])

const selectedChain = ref('')
const selectedToken = ref('')
const currentStep = ref(1)
const currentAmount = ref(null)

// Logo handling functions
const getChainLogo = (chain) => {
  const chainLower = chain.toLowerCase()
  try {
    return require(`@/assets/${chainLower}-logo.webp`)
  } catch {
    return '/placeholder.png' // Fallback to a static public asset
  }
}

const getTokenLogo = (chain, token) => {
  const chainLower = chain.toLowerCase()
  const tokenLower = token.toLowerCase()
  try {
    return require(`@/assets/${chainLower}-${tokenLower}.webp`)
  } catch {
    return '/placeholder.png' // Fallback to a static public asset
  }
}

const handleImageError = (event) => {
  event.target.src = '/placeholder.png'
}

// Environment variables with fallbacks
const availableChains = computed(() => {
  try {
    return import.meta.env.VITE_AVAILABLE_CHAINS?.split(',') || DEFAULT_CONFIG.chains
  } catch {
    return DEFAULT_CONFIG.chains
  }
})

const chainTokens = computed(() => {
  try {
    return {
      Solana: import.meta.env.VITE_SOLANA_TOKENS?.split(',') || DEFAULT_CONFIG.tokens.Solana,
      Arbitrum: import.meta.env.VITE_ARBITRUM_TOKENS?.split(',') || DEFAULT_CONFIG.tokens.Arbitrum
    }
  } catch {
    return DEFAULT_CONFIG.tokens
  }
})

// Computed
const cartTotal = computed(() => {
  return cartItems.value.reduce((sum, item) => sum + item.price * item.quantity, 0)
})

const totalQuantity = computed(() => {
  return cartItems.value.reduce((sum, item) => sum + item.quantity, 0)
})

const availableTokens = computed(() => {
  return selectedChain.value ? chainTokens.value[selectedChain.value] : []
})

// Methods
const selectChain = (chain) => {
  selectedChain.value = chain
  selectedToken.value = ''
  currentStep.value = 2
}

const selectToken = async (token) => {
  selectedToken.value = token
  currentStep.value = 3
  await fetchCryptoPrice()
}

const fetchCryptoPrice = async () => {
  try {
    const response = await fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=${selectedToken.value.toLowerCase()}&vs_currencies=usd`
    )
    const data = await response.json()
    const price = data[selectedToken.value.toLowerCase()].usd
    currentAmount.value = (cartTotal.value / price).toFixed(6)
  } catch (error) {
    console.error('Error fetching crypto price:', error)
    currentAmount.value = null
  }
}

const handlePayment = async () => {
  // Implement wallet connection and payment logic here
  currentStep.value = 4
}

const getStepLabel = (step) => {
  const labels = {
    1: 'Select Chain',
    2: 'Select Token',
    3: 'Connect Wallet',
    4: 'Confirm Payment',
    5: 'Complete'
  }
  return labels[step]
}
</script>